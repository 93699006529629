import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";

import { Modal as SecondScreenModal } from "@kameleon-common/second-screen";
import { KameleonGlobalTheme } from "../elements/kameleon-global-theme";
import { Codes } from "../../config/Codes";
import { Uris } from "../../config/Uris";
import { setupGatsbyHMRWebsocket } from "./development-hmr-websocket";

class DevelopmentError {
  isClosed: boolean;

  constructor(isClosed: boolean) {
    this.isClosed = isClosed;
  }

  static initial(): DevelopmentError | null {
    return null;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const developmentErrorModal = (error: DevelopmentError | null) => {
  if (!error?.isClosed) return null;
  return (
    <KameleonGlobalTheme>
      <SecondScreenModal
        title={Codes.noConnection.title}
        description={Codes.noConnection.description}
        codes={{
          buttons: {
            submit: Codes.buttons.enterCode,
          },
        }}
        onSubmit={(): void => {
          window.location.assign(Uris.secondScreen.home());
        }}
        closeDisabled
        onClose={(): void => {}}
      />
    </KameleonGlobalTheme>
  );
};

// eslint-disable-next-line max-lines-per-function
export function DevelopmentConnectionError({
  children,
  ...props
}: PropsWithChildren): ReactElement {
  if (process.env.NODE_ENV === "production") {
    return <div>{children}</div>;
  }

  const [developmentWsLoaded, setDevelopmentWsLoaded] = useState(false);
  const [developmentError, setDevelopmentError] = useState(
    DevelopmentError.initial
  );

  useEffect(() => {
    if (!developmentWsLoaded) {
      setupGatsbyHMRWebsocket(
        Uris.gatsby.hotReloadWsUrl(),
        () => {
          setDevelopmentError(new DevelopmentError(false));
        },
        () => {
          setDevelopmentError(new DevelopmentError(true));
        },
        () => {
          window.location.reload();
        }
      );
      setDevelopmentWsLoaded(true);
    }
  });

  return (
    <>
      {developmentErrorModal(developmentError)}
      {!developmentError && children}
    </>
  );
}
