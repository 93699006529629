import React from "react";
import { Footer as FooterProps } from "@kameleon-gatsby/gatsby-kameleon-data-source/types";
import { KameleonFooterWidget } from "@kameleon-util/manifest";
import { BlockType } from "@kameleon-core/types";
import DynamicLoader from "../util-components/dynamic-loader";

export default function Footer(footerProps: FooterProps) {
  const block = footerProps?.block;
  if (!block) return null;

  return (
    <DynamicLoader
      type={BlockType.Footer}
      component={block.packageName as string}
      widgets={footerProps?.widgets as KameleonFooterWidget[]}
      blockProps={{
        ...(block?.blockProps ?? {}),
      }}
    />
  );
}
