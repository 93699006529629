import React, { useContext } from "react";
import { Header as HeaderProps } from "@kameleon-gatsby/gatsby-kameleon-data-source/src/types";
import { BlockType } from "@kameleon-core/types";
import { KameleonNavWidget } from "@kameleon-util/manifest";
import { NavigationContext } from "../util-components/header-provider";
import DynamicLoader from "../util-components/dynamic-loader";

export default function Header(headerProps: HeaderProps) {
  const { isAbsolute } = useContext(NavigationContext);

  const block = headerProps?.block;
  if (!block) return null;

  const layoutProps = block?.blockProps?.layoutProps;
  return (
    <DynamicLoader
      type={BlockType.Header}
      component={block.packageName as string}
      secondaryBlock={block?.secondaryBlock}
      widgets={headerProps?.widgets as KameleonNavWidget[]}
      blockProps={{
        ...(block?.blockProps ?? {}),
        menu: headerProps?.menu,
        layoutProps: {
          ...(layoutProps ?? {}),
          _positionAbsolute: isAbsolute,
        },
      }}
    />
  );
}
