import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from "react";

interface NavigationContextType {
  isAbsolute: boolean;
  setNavigationAbsolute: (value: boolean) => void;
}

export const NavigationContext = createContext<NavigationContextType>({
  isAbsolute: false,
  setNavigationAbsolute: (_value) => {},
});

export function NavigationProvider({ children }: PropsWithChildren) {
  const [isAbsolute, setIsAbsolute] = useState(false);

  const setNavigationAbsolute = (value: boolean) => {
    setIsAbsolute(value);
  };

  // Memoize the context value
  const contextValue = useMemo(
    () => ({ isAbsolute, setNavigationAbsolute }),
    [isAbsolute, setNavigationAbsolute]
  );

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
}
