import React, { PropsWithChildren, ReactElement } from "react";

/**
 * When rendering Kameleon sections/elements from the manifest, it has their own css applied to them.
 * We don't want to apply the Kameleon Gatsby global styling (styles/main.scss) to these elements.
 *
 * However, we still have some components that require this styling within the context of rendering the entire site.
 * For example, when something went wrong (error handling), we show some information within a modal.
 *
 * These kind of components we DO want to be styled with our custom css. Therefor, if components wants this global style,
 * they should wrap these components in this <KameleonGlobalTheme> component for them to have this style applied.
 * @param children
 * @constructor
 */
export function KameleonGlobalTheme({
  children,
}: PropsWithChildren): ReactElement {
  return <div className="kameleon-gatsby__global-theme">{children}</div>;
}
