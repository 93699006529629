export const replaceObjectKeysWith = (
  keyName: string,
  toKeyName: string,
  inJson: object
): object =>
  JSON.parse(
    JSON.stringify(inJson).replaceAll(`"${keyName}":`, `"${toKeyName}":`)
  );

const graphqlKeywords = {
  input: "___input",
};

export const escapeGraphQLKeywordsIn = (
  inJson: object,
  unescape = false
): object => {
  let updatedJson = { ...inJson };
  Object.entries(graphqlKeywords).map(
    // eslint-disable-next-line array-callback-return
    ([escapingKeyword, escapedKeyword]): void => {
      if (escapedKeyword.length > 0 && escapingKeyword.length > 0) {
        if (unescape) {
          updatedJson = replaceObjectKeysWith(
            escapedKeyword,
            escapingKeyword,
            updatedJson
          );
        } else {
          updatedJson = replaceObjectKeysWith(
            escapingKeyword,
            escapedKeyword,
            updatedJson
          );
        }
      }
    }
  );
  return updatedJson;
};
